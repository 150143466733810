@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700&display=swap');

* {
  font-family: "Source Sans Pro";
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/source-sans-pro-v13-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('./assets/fonts/source-sans-pro-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/source-sans-pro-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/source-sans-pro-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}

main {
  display: block;
}

article {
  display: block;
}

/** Button **/

.btn {
  border-radius: 40px;
  min-height: 50px;
  font-weight: 500;
  min-width: 170px;
  font-size: 17px;
  padding: 10px 25px;
  cursor: pointer;
  word-break: break-word;
  white-space: normal;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}

.btn-primary,
.btn-primary:focus {
  background: #333;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(52, 58, 64, .5)
}

.btn-sec {
  background: #ffffff;
  color: #000;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .2);
}

.btn-outline-dark:hover {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-sec:hover {
  color: #fff;
  background-color: #292929;
  border-color: #6c757d;
}

.btn.disabled:hover,
.btn.disabled:focus,
.btn:disabled:hover,
.btn:disabled:focus {
  background: #fff !important;
  opacity: .65 !important;
  color: #000 !important;
  transition: none;
}

@media (max-width:767px) {
  .btn {
    font-size: 16px;
  }
}