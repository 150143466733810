@import '../../theme/theme.scss';

.game-container {
    position: relative;
    @include flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @include justify-content(space-between);
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    color: #fff;
    -webkit-box-shadow: inset 0 0 10em 1em rgba(0, 0, 0, .8);
    box-shadow: inset 0 0 10em 1em rgba(0, 0, 0, .8);

    >img {
        position: absolute;
        object-fit: cover;
    }
}